import React from 'react';
import './Map.css';
import API_KEY from './API_KEY.js';

const $script = require("scriptjs");

class Map extends React.Component {

  constructor(props) {
    super(props);
    this.addMarker = this.addMarker.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      map: null,
      markers: null
    };
  };

  handleClick(index) {
    this.props.onRoomSelected(index);
  }

  addMarker(map, index) {
    const google = window.google;
    const room = this.props.rooms[index];
    const latLng = {lat: parseFloat(room.lat), lng: parseFloat(room.lng)};
    let marker = new google.maps.Marker({
      map: map,
      position: latLng,
      title: room.name,
      icon: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
    });
    marker.id = room.id;

    let address = room.address + ', ' + room.city + ', ' + room.province + ', ' + room.postal; 
    let contentString = '<h1>' + room.name + '</h1>' +
      '<p>' + address + '</p>' +
      '<p>' + room.phone + '</p>'

    if(room.website)
      contentString += '<p><a href="' + room.website + '" target="_blank" rel="noopener noreferrer">' + room.website + '</a></p>';

    let infowindow = new google.maps.InfoWindow({
      content: contentString
    });

    marker.addListener('click', () => {
      infowindow.open(map, marker);
      this.handleClick(index);
    });

    marker.infowindow = infowindow;

    return marker;
  }

  componentDidMount() {
    // expose React for UMD build
    window.React = React;
    // async load of remote UMD component
    $script(`https://maps.googleapis.com/maps/api/js?key=${API_KEY}`, () => {
        // loaded OK
        const google = window.google;
      	let map = new google.maps.Map(document.getElementById('map'), {
		zoom: 10.38,
		center: {lat: 43.7453663, lng: -79.5437399}
	});

        let markers = this.props.rooms.map((room, index) => {
          return this.addMarker(map, index);
        });

        this.setState({
          map: map,
          markers: markers
        });

        let marker = markers[this.props.selectedIndex];
        if (marker != null) {
          marker.setIcon('https://maps.google.com/mapfiles/ms/icons/green-dot.png');
          marker.infowindow.open(this.state.map, marker);
        }

        marker = markers[this.props.hoverIndex];
        if (marker != null) {
          marker.setIcon('https://maps.google.com/mapfiles/ms/icons/blue-dot.png');
        }
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.selectedIndex === nextProps.selectedIndex && this.props.hoverIndex === nextProps.hoverIndex) {
      return false;
    } else {
      return true;
    }
  }

  componentDidUpdate() {
    if(!this.state.markers) return null;

    this.state.markers.forEach((m) => {
      m.infowindow.close();
      m.setIcon('https://maps.google.com/mapfiles/ms/icons/red-dot.png');
    });

    const {markers} = this.state;
    let marker = markers[this.props.selectedIndex];
    if (marker != null) {
      marker.setIcon('https://maps.google.com/mapfiles/ms/icons/green-dot.png');
      marker.infowindow.open(this.state.map, marker);
    }

    marker = markers[this.props.hoverIndex];
    if (marker != null) {
      marker.setIcon('https://maps.google.com/mapfiles/ms/icons/blue-dot.png');
    }
  }



  render() {
      return <div id="map"/>
  }

}

export default Map;
