// https://stackoverflow.com/a/55996695/11899707
import React from 'react';

let makeCompareBy = (by) => {
    return function(a, b) {
      //console.log(a.props.room[by]);
      switch(typeof(by)) {
        case 'number':
          return a.props.room[by] > b.props.room[by];
        case 'string':
          return ('' + a.props.room[by]).localeCompare(b.props.room[by]);
        default:
          return a > b;
      }
    }
}

let Sort = ({children, by}) => {
  if(!by) {
    return children;
  } else {
    let compareBy = makeCompareBy(by);
    return React.Children.toArray(children).sort(compareBy);
  }
}

export default Sort;
