// https://stackoverflow.com/a/55996695/11899707
import React from 'react';

let filter = (arr, searchText) => {
  let i = 0;
  let j = 0;
  while(i < arr.length) {
    if (arr[i].props.room.name.toLowerCase().indexOf(searchText) !== -1) {
      arr[j] = arr[i];
      j++;
    }
    i++;
  }

  arr.length = j;
}

let Search = ({children, searchText}) => {
  if(searchText) {
    // This is a bit confusing because Sort is the child component of
    // Search, and RoomDetail is a child of Sort
    // this.props.children == Sort
    // this.props.children.props == Sort.props
    // this.props.children.props.children = Sort.props.children == array of RoomDetails
    //
    // Next, in the filter, child is a RoomDetail, so child.props.room is the
    // {room} passed in from MapList

    // This was the original approach, but it never actually rendered the direct
    // child, only the grandchild.  Thus, when this was called, the child (Sort)
    // was never actually called
    //const {children} = this.props.children.props;
    //let filtered = React.Children.toArray(children).filter(child => child.props.room.name.toLowerCase().indexOf(this.state.searchText) !== -1);
    //return React.Children.toArray(children.props.children).filter(child => child.props.room.name.toLowerCase().indexOf(searchText) !== -1);

    // This approach filtered the grandchild array in place, since
    // array.filter() only returns a copy
    let newChildren = React.Children.toArray(children);
    filter(newChildren[0].props.children, searchText);
    let filtered = React.cloneElement(children, {children: newChildren});
    return filtered;

  } else {
    return children;
  }
}

export default Search;
