import React from 'react';
import './Navbar.css';

class Navbar extends React.Component {
  render() {
    const {isMobile} = this.props;
    return (
      <div className={"navbar" + (isMobile ? '-mobile' : '')}>
        <div className="navbar-logo">esc6ix.com | <a href="list.html">View List</a> | <a href="blog.html">Blog</a></div>
        <div className="navbar-message"></div>
        <div className="navbar-contact">Submit new rooms and corrections to: <a href="mailto:info@esc6ix.com">info@esc6ix.com</a></div>
      </div>
    )
  }
}

export default Navbar;
