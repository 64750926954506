import React from 'react';
import Navbar from './Navbar';
import Map from './Map';
import MapList from './MapList';
import TwoColumnLayout from './TwoColumnLayout';
import MobileLayout from './MobileLayout';
import escaperooms from './escaperooms.js';
import './style.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rooms: escaperooms,
      selectedIndex: null,  // index into rooms array, NOT the escaperoom id
      isMobile: window.innerWidth < 800,
      hoverIndex: null
    };
    this.handleRoomSelected = this.handleRoomSelected.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleRoomHover = this.handleRoomHover.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.setState({isMobile: window.innerWidth < 800 });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    this.setState({isMobile: window.innerWidth < 800 });
  }

  handleRoomSelected(index) {
    if (index === this.state.selectedIndex) {
      return;
    } else {
      this.setState({selectedIndex: index});
    }
  }

  handleRoomHover(index, isOn) {
    if (isOn === false) {
      this.setState({hoverIndex: null});
    } else if (index === this.state.hoverIndex) {
      return;
    } else {
      this.setState({hoverIndex: index});
    }
  }

  render() {
    let desktopLayout = (
      <div className="container">
        <Navbar isMobile={false}></Navbar>
        <TwoColumnLayout
                left={<MapList rooms={this.state.rooms} selectedIndex={this.state.selectedIndex} onRoomSelected={this.handleRoomSelected} onHover={this.handleRoomHover} />}
                right={<Map rooms={this.state.rooms} selectedIndex={this.state.selectedIndex} onRoomSelected={this.handleRoomSelected} hoverIndex={this.state.hoverIndex} />}
        />
      </div>
    );
    let mobileLayout = (
      <div className="container">
        <Navbar isMobile={true}></Navbar>
        <MobileLayout
                map={<Map rooms={this.state.rooms} selectedIndex={this.state.selectedIndex} onRoomSelected={this.handleRoomSelected} hoverIndex={this.state.hoverIndex} />}
                list={<MapList rooms={this.state.rooms} selectedIndex={this.state.selectedIndex} onRoomSelected={this.handleRoomSelected} onHover={this.handleRoomHover} />}
        />
      </div>
    )

    return this.state.isMobile ? mobileLayout : desktopLayout;
  }
}

export default App;
