import React from 'react';
import './MobileLayout.css';

const MobileLayout = ({map, list}) => {
  return (
    <div className="mobile-container">
      <div className="mobile-map">{map}</div>
      <div className="mobile-list">{list}</div>
    </div>
  );
};

export default MobileLayout;
