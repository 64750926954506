import React from 'react';
import './MapList.css';
import Search from './Search';
import Sort from './Sort';

class RoomDetail extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.ref = React.createRef();
  }

  handleClick(index) {
    this.ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    this.props.onRoomSelected(index);
  }

  // this hover handler only propagates the state of the hover up to App, so
  // that the Map can highlight the corresponding marker.  The hover style is
  // handled by CSS, because the selected style is handled here by react (as
  // well as implementing the auto-scroll)
  handleHover(index, isOn) {
    this.props.onHover(index, isOn);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.selected !== nextProps.selected) {
      return true;
    } else {
      return false;
    }
  }

  // This is meant to auto-scroll when the selected state changes from the prop.
  // However, it's causing a bug because when the hover happens, it's also
  // triggering this update
  componentDidUpdate() {
    if(this.props.selected) {
      this.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  render() {
    const {room, index, selected} = this.props;
    let roomDetail = (
      <div className={"room-detail " + (selected ? 'selected ' : '')} ref={this.ref} onClick={this.handleClick.bind(null, index)} onMouseEnter={this.handleHover.bind(null, index, true)} onMouseLeave={this.handleHover.bind(null, index, false)}>
        <div className="room-detail-cost">{room.cost.split(' ')[0]}</div>
        <div className="room-detail-name">{room.name}</div>
        <div className="room-detail-neighborhood">{room.neighborhood}</div>
        {selected &&
          <div className="room-detail-info">
            <p><b>Address:</b> {room.address}, {room.city}, {room.province}, {room.postal}</p>
            <p><b>Phone:</b> {room.phone}</p>
            <p><b>Duration:</b> {room.duration}</p>
            <p><b>Website:</b> <a href={room.website} target="_blank" rel="noopener noreferrer">{room.website}</a></p>
            <p><b>Rooms:</b> {room.rooms.split('\n').join(', ')}</p>
            <p><b>Hours:</b> {room.hours}</p>
            <p><b>Rating:</b> {room.rating}</p>
            <p><b>Comments:</b> {room.comments}</p>
          </div>
        }
      </div>
    );
    return roomDetail;
  }
}

class MapListSearch extends React.Component {
  constructor(props) {
    super(props);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
  }

  handleSearchTextChange(event) {
    let text = event.target.value.toLowerCase();
    this.props.onSearchTextChange(text);
  }

  render() {
    return (
      <div className="MapListSearch">
        <label htmlFor="search">Search: </label>
        <input type="text" onChange={this.handleSearchTextChange} />
      </div>
    );
  }

}

class MapListFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterChange(event) {
    this.props.onFilterChange(event.target.value);
  }

  render() {
    return (
      <div className="MapListFilter">
        <label htmlFor="sortBy">Sort by:</label>
        <select name="sortBy" onChange={this.handleFilterChange}>
          <option value="name">Name</option>
          <option value="cost">Cost</option>
          <option value="neighborhood">Neighborhood</option>
        </select>
      
      </div>
    );
  }

}

class MapList extends React.Component {
  
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.state = {
      sortBy: 'name',
      searchText: ''
    }
    this.handleHover = this.handleHover.bind(this);
  }

  handleClick(index, ref) {
    this.props.onRoomSelected(index);
  }

  handleFilterChange(filter) {
    this.setState({sortBy: filter});
  }

  handleSearchTextChange(text) {
    this.setState({searchText: text});
  }

  handleHover(index, isOn) {
    this.props.onHover(index, isOn);
  }

  render() {
    let escaperooms = this.props.rooms.map((room, index) => {
      return <RoomDetail key={room.id} room={room} index={index} selected={index === this.props.selectedIndex} onRoomSelected={this.handleClick} onHover={this.handleHover} />
    });

    return (
      <React.Fragment>
        <div className="search-bar">
          <MapListSearch onSearchTextChange={this.handleSearchTextChange} />
          <MapListFilter onFilterChange={this.handleFilterChange} />
        </div>
        <div className="MapList">
          <Search searchText={this.state.searchText}>
            <Sort by={this.state.sortBy}>
                {escaperooms}
            </Sort>
          </Search>
        </div>
      </React.Fragment>
    )
  }
}

export default MapList;
